import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React from 'react';

export const Header = ({ siteTitle, siteDescription }) => (
    <AniLink swipe direction="right" to="/" style={{
        color: '#fff',
        textDecoration: 'none'
    }}>
        <h3>{siteTitle}</h3>
    </AniLink>
);