import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
    const { site } = useStaticQuery(
    graphql`
        query SITE_METADATA_QUERY {
            site {
                siteMetadata {
                    title
                    description
                    siteUrl
                    siteLanguage
                    siteLocale
                    twitterUsername
                    authorName
                    authorDesc
                    frontPage {
                        heading
                    }
                }
            }
        }
    `
    );
    return site.siteMetadata;
};