import React, {useState,useEffect} from 'react';
import styled from 'styled-components';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { Header } from './Header';
import convert from 'color-convert'
import '../styles/main.scss';
import '../styles/customise.scss';

const AppStyles = styled.main`
    max-width: 1280px;
    padding: 0 2rem;
    margin: 0 auto;
    perspective: 90em;
`;
const date = new Date();
export const Layout = ({ children }) => {
    const { title, description } = useSiteMetadata();
    const isSSR = typeof window === 'undefined'
    let initials
    if (!isSSR) {
        initials = {
            borderColor: localStorage.getItem('borderColor') || '#ffffff',
            textColor: localStorage.getItem('textColor') || '#ffffff',
            backgroundColor: localStorage.getItem('backgroundColor') || '#151515',
            borderRadius: localStorage.getItem('borderRadius') || '16',
            highlightColor: localStorage.getItem('highlightColor') || '#ffffff',
            highlightAlpha: localStorage.getItem('highlightAlpha') || '0.3',
            gridSize: localStorage.getItem('gridSize') || '300',
            gridGap: localStorage.getItem('gridGap') || '16',
            baseFont: localStorage.getItem('baseFont') || '16',
            featureColor: localStorage.getItem('featureColor') || '#00eeee',
        }
    } else {
        initials = {
            borderColor: '#ffffff',
            textColor: '#ffffff',
            backgroundColor: '#151515',
            borderRadius: '16',
            highlightColor: '#ffffff',
            highlightAlpha: '0.3',
            gridSize: '300',
            gridGap: '16',
            baseFont: '16',
            featureColor: '#00eeee',
        }
    }
    
    const [borderColor,setBorderColor] = useState(initials.borderColor)
    const [textColor,setTextColor] = useState(initials.textColor)
    const [backgroundColor,setBackgroundColor] = useState(initials.backgroundColor)
    const [borderRadius, setBorderRadius] = useState(initials.borderRadius)
    const [highlightColor,setHighlightColor] = useState(initials.highlightColor)
    const [highlightAlpha,setHighlightAlpha] = useState(initials.highlightAlpha)
    const [gridSize, setGridSize] = useState(initials.gridSize)
    const [gridGap, setGridGap] = useState(initials.gridGap)
    const [baseFont, setBaseFont] = useState(initials.baseFont)
    const [featureColor, setFeatureColor] = useState(initials.featureColor)
    useEffect(() => {
        if (!isSSR) {
            localStorage.setItem('borderColor', borderColor)
            localStorage.setItem('textColor', textColor)
            localStorage.setItem('backgroundColor', backgroundColor)
            localStorage.setItem('borderRadius', borderRadius)
            localStorage.setItem('highlightColor', highlightColor)
            localStorage.setItem('highlightAlpha', highlightAlpha)
            localStorage.setItem('gridSize', gridSize)
            localStorage.setItem('gridGap', gridGap)
            localStorage.setItem('baseFont', baseFont)
            localStorage.setItem('featureColor', featureColor)
        }
    })
    return (
        <div>
            <aside className='customise'>
                <label>
                    Background Color
                    <input type='color' value={backgroundColor} onChange={(e) => {setBackgroundColor(e.target.value)}} />
                </label>
                <label>
                    Text Color
                    <input type='color' value={textColor} onChange={(e) => {setTextColor(e.target.value)}} />
                </label>
                <label>
                    Feature Color
                    <input type='color' value={featureColor} onChange={(e) => {setFeatureColor(e.target.value)}} />
                </label>
                <label>
                    Highlight Color
                    <input type='color' value={highlightColor} onChange={(e) => {setHighlightColor(e.target.value)}} />
                </label>
                <label>
                    Highlight Alpha
                    <input type='range' value={highlightAlpha} min='0' max='1' step='0.1' onChange={(e) => setHighlightAlpha(e.target.value)} />
                </label>
                <label>
                    Border Color
                    <input type='color' value={borderColor} onChange={(e) => {setBorderColor(e.target.value)}} />
                </label>
                <label>
                    Border Radius
                    <input type='range' value={borderRadius} min='0' max='100' onChange={(e) => {setBorderRadius(e.target.value)}} />
                </label>
                <label>
                    Cell Size
                    {
                        !isSSR && <input type='range' value={gridSize} min='100' max={window.innerWidth < 1280 ? window.innerWidth * 0.8 : 1280} onChange={(e) => {setGridSize(e.target.value)}} />
                    }
                </label>
                <label>
                    Grid Gap
                    <input type='range' value={gridGap} min='0' max='100' onChange={(e) => {setGridGap(e.target.value)}} />
                </label>
                <label>
                    Font Size
                    <input type='range' value={baseFont} min='12' max='24' onChange={(e) => {setBaseFont(e.target.value)}} />
                </label>
            </aside>
            <AppStyles>
                <style
                    dangerouslySetInnerHTML={{
                        __html: `
                            :root {
                                --border-color: ${borderColor};
                                --text-color: ${textColor};
                                --background-color:${backgroundColor};
                                --highlight-color: rgba(${convert.hex.rgb(highlightColor.substring(1))[0]}, ${convert.hex.rgb(highlightColor.substring(1))[1]}, ${convert.hex.rgb(highlightColor.substring(1))[2]}, ${highlightAlpha});
                                --border-radius: ${borderRadius}px;
                                --grid-size: ${gridSize}px;
                                --grid-gap: ${gridGap}px;
                                --base-font: ${baseFont}px;
                                --feature-color: ${featureColor};
                            }
                        `
                    }}
                ></style>
                <Header siteTitle={title} siteDescription={description} />
                {/* <link rel='stylesheet' href={mainStyle} /> */}
                {children}
                <footer>
                    <p style={{
                        margin: '3em 0 1em'
                    }}>Copyright {date.getFullYear()}</p>
                </footer>
            </AppStyles>
        </div>
    );
};